import React, {useState} from 'react';
import Card from "../components/card";
import {FieldGroup, Fieldset, Label, Field} from "../components/fieldset";
import {Input} from "../components/input";
import {Button} from "../components/button";
import client from "../lib/axios";
import {Badge} from "../components/badge";
import {useNavigate} from "react-router-dom";
import {Loader} from "../components/loader";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState();

    const [authError, setAuthError] = useState(false)

    let alertTimeout = null;

    const showError = () => {
        setAuthError(true)

        clearTimeout(alertTimeout);

        alertTimeout = setTimeout(() => {
            setAuthError(false)
        }, 6000)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        if (username === "" || password === "") {
            showError();
        }

        setLoading(true)

        client.post('/login', {username, password})
            .then((response) => {
                sessionStorage.setItem('sid', response.data.sid);
                navigate('/search')
            })
            .catch((error) => {
                console.error(error);

                showError();
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return <div className="flex items-center md:items-start justify-center h-screen md:mt-[20vh] md:h-auto">
        <Card>
            {authError && <Badge className="w-full p-4 mb-4" color="rose">
                <div className="text-center w-full p-2">Invalid username or password</div>
            </Badge>}
            <Fieldset>
                <span className="text-center">
                  <img className="max-w-36 m-auto" src="/img/myq_logo.png" alt="myq logo"/>
                  <h1 className="mt-3 text-xl mb-4 text-zinc-500 font-sans">Smart Assistant</h1>
                </span>
                <form onSubmit={handleLogin}>
                    <FieldGroup>
                        <Field>
                            <Label>Username</Label>
                            <Input
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Field>
                        <Field>
                            <Label>Password</Label>
                            <Input
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Field>
                        <Field>
                            {loading
                                ? <div className="w-full text-center"><Loader/></div>
                                : <Button className="w-full" type="submit" color="sky">Login</Button>}
                        </Field>
                    </FieldGroup>
                </form>
            </Fieldset>
        </Card>
    </div>
};

export default Login;
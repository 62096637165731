import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/login";
import Assistant from "./pages/assistant";
import PrivateRoute from "./lib/private_route";
import Search from "./pages/search";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="assistant" element={<PrivateRoute element={Assistant} />} />
        <Route path="search" element={<PrivateRoute element={Search} />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

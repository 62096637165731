import React, {useEffect, useState} from 'react';
import Card from "../components/card";
import {FieldGroup, Fieldset, Field, Label} from "../components/fieldset";
import {Button} from "../components/button";
import {Textarea} from "../components/textarea";
import {Dropdown} from "../components/dropdown";
import client from "../lib/axios";
import {useNavigate} from "react-router-dom";
import {Loader} from "../components/loader";
import {Input} from "../components/input";
import {Divider} from "../components/divider";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../components/dialog";
import {Badge} from "../components/badge";

const Search = () => {
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [mp4, setMp4] = useState();
    const [keywordsGenerated, setKeywordsGenerated] = useState();
    const [dialogTitle, setDialogTitle] = useState();
    const [loading, setLoading] = useState();
    const navigate = useNavigate()
    const [keywords, setKeywords] = useState('');
    const [vlmModels, setVLModels]  = useState(["gpt-4o", "gemini-1.5-flash"]);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const options = ["gpt-4o", "gemini-1.5-flash", "gemini-1.5-pro", "anthropic.claude-3-5-sonnet-20240620-v1:0",
        "anthropic.claude-3-sonnet-20240229-v1:0", "anthropic.claude-3-haiku-20240307-v1:0", "anthropic.claude-3-5-sonnet-20241022-v2:0"];

    const toggleDropdown = () => setIsDropDownOpen(!isDropDownOpen);

    useEffect(() => {
        handleSubmit()

        window.addEventListener("mouseup", (e) => {
            if (e.target.closest(".dropdown") === null) {
                setIsDropDownOpen(false);
            }
        });
    }, []);

    const setDialog = (s3_video_path, title, keywordsGenerated) => {
        setMp4(s3_video_path)
        setDialogTitle(title)
        setIsOpen(true)
        setKeywordsGenerated(keywordsGenerated)

    }

    const handleOptionClick = (option) => {
        if (vlmModels.includes(option)) {
            setVLModels(vlmModels.filter((item) => item !== option));
        } else {
            setVLModels([...vlmModels, option]);
        }
    };

    const handleSubmit = (e) => {
        if (e != null) {
            e.preventDefault()
        }
        if (loading) {
            return;
        }
        setLoading(true)
        console.log({keywords, vlmModels})
        client.post('/assistant', {keywords, vlmModels}, {
            headers: {
                "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
            }
        })
            .then((response) => {
                // group results by date
                const groupedHits = {};

                vlmModels.forEach(model => {
                    groupedHits[model] = {};
                });
                response.data.hits.hits.forEach(hit => {
                    const date = hit._source.startDttm.split("T")[0]; // get date part
                    const qVlmModel = hit._source.vlmModel; // get VLM model
                    const eventId = hit._source.eventId; // get event id

                    if (vlmModels.includes(qVlmModel)) {
                        if (!groupedHits[qVlmModel][date]) {
                            groupedHits[qVlmModel][date] = {};
                        }

                        if (!groupedHits[qVlmModel][date][eventId]) {
                            groupedHits[qVlmModel][date][eventId] = [];
                        }
                    }

                    if (typeof groupedHits[qVlmModel] === "undefined") {
                        groupedHits[qVlmModel] = {}
                    }

                    if (typeof groupedHits[qVlmModel][date] === "undefined") {
                        groupedHits[qVlmModel][date] = {}
                    }

                    if (typeof groupedHits[qVlmModel][date][eventId] === "undefined") {
                        groupedHits[qVlmModel][date][eventId] = []
                    }

                    groupedHits[qVlmModel][date][eventId].push(hit);
                })

                // Gather all unique dates across all models
                const allDates = new Set();
                vlmModels.forEach(model => {
                    if (groupedHits[model]) {
                        Object.keys(groupedHits[model]).forEach(date => {
                            allDates.add(date);
                        });
                    }
                });

                // Ensure all models have entries for all dates
                vlmModels.forEach(model => {
                    allDates.forEach(date => {
                        if (!groupedHits[model][date]) {
                            groupedHits[model][date] = {}; // Create empty date for the model
                        }
                        
                        // Collect all eventIds for the date across models
                        const allEventIds = new Set();
                        vlmModels.forEach(vlm => {
                            if (groupedHits[vlm][date]) {
                                Object.keys(groupedHits[vlm][date]).forEach(eventId => {
                                    allEventIds.add(eventId);
                                });
                            }
                        });

                        // Ensure each model has all eventIds for the date
                        allEventIds.forEach(eventId => {
                            if (!groupedHits[model][date][eventId]) {
                                groupedHits[model][date][eventId] = [];
                            }
                        });
                    });
                });

                setData(groupedHits);
                console.log("groupedhits", groupedHits)
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 403) {
                    sessionStorage.removeItem("sid")
                    navigate("/login")
                } else {
                    console.error(error)
                    setData([]);
                }
            })
            .finally(() => {
                setKeywords("")
                setLoading(false)
            })

    }

    const renderResults = () => {
        return (
            <div className="flex gap-4">
                {vlmModels.map(vlmModel => (
                    <div key={vlmModel} className="w-[400px]">
                        <h3 className="font-semibold text-center py-2">{vlmModel}</h3>

                        {Object.keys(data[vlmModel] || {}).length > 0 ? (
                            Object.keys(data[vlmModel]).map(date => (
                                <div key={date}>
                                    <Divider/>
                                    <h4 className="font-bold py-2">{date}</h4>
                                    <Divider/>
                                    {(() => {
                                        const eventIds = new Set();
                                        vlmModels.forEach(model => {
                                            if (data[model] && data[model][date]) {
                                                Object.keys(data[model][date]).forEach(eventId => {
                                                    eventIds.add(eventId);
                                                });
                                            }
                                        });
                                        return Array.from(eventIds);
                                    })().map(eventId => (
                                        <div key={eventId} className="flex gap-4 items-stretch py-2">
                                            {data[vlmModel][date] && data[vlmModel][date][eventId] && data[vlmModel][date][eventId].length > 0 ? (
                                                data[vlmModel][date][eventId].map(hit => {
                                                    const prettyDttm = new Date(hit._source.startDttm).toLocaleDateString('en-US', {
                                                        weekday: 'short',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit'
                                                    });

                                                    return (
                                                        <div key={hit._id}
                                                             className="flex items-center gap-4 flex-1 min-h-[8rem] cursor-pointer"
                                                             onClick={() => setDialog(hit._source.s3VideoPath, hit._source.notification, hit._source.keywords)}>
                                                            <img className="h-16 rounded-xl bg-gray-50"
                                                                 src={hit._source.s3ThumbnailPath} alt="Thumbnail"/>
                                                            <div>
                                                                <Badge color="sky">{prettyDttm}</Badge>
                                                                <div>{hit._source.notification}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                // Placeholder if matching event not found
                                                <div
                                                    className="flex items-center justify-center border-b text-gray-400 w-full flex-1 min-h-[8rem]">
                                                    No data found
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            // Placeholder for if not data for model found
                            <div className="text-center text-gray-500 py-4">No data available</div>
                        )}
                    </div>
                ))}
            </div>
        );
    };
    
    return (
        <div className="flex items-center md:items-start justify-center h-screen md:h-auto">
            <Card className="relative">
                {/* Dropdown positioned above the form */}
                <div className="relative inline-block w-full mb-4">
                    <button
                        type="button"
                        onClick={toggleDropdown}
                        className="w-full px-4 py-2 text-left bg-white border rounded shadow-sm focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-indigo-300"
                    >
                        {vlmModels.length > 0 ? vlmModels.join(', ') : 'Select your VLM models'}
                        <span className="float-right">▼</span>
                    </button>
                    {isDropDownOpen && (
                        <div className="dropdown absolute z-50 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-y-auto"
                            style={{ top: '100%', left: 0 }}>
                            {options.map((option, index) => (
                                <label key={index} className="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                                    <input
                                        type="checkbox"
                                        checked={vlmModels.includes(option)}
                                        onChange={() => handleOptionClick(option)}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
    
                {/* Form positioned below the dropdown */}
                <form onSubmit={handleSubmit}>
                    <Fieldset className="w-[400px]">
                        <div className="flex justify-between">
                            <img className="h-12" src="/img/myq_logo.png" alt="myq logo" />
                            <h1 className="mt-3 text-xl mb-4 text-zinc-500 font-sans">Smart Assistant</h1>
                        </div>
                        <FieldGroup>
                            <Field>
                                <div className="h-[400px] overflow-hidden overflow-y-auto">
                                    {Object.keys(data).length === 0 ?
                                        <div>No results found</div> : renderResults()}
                                </div>
                            </Field>
                            <Field>
                                <Input
                                    placeholder="Enter keywords"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                />
                            </Field>
                            <Field>
                                {loading
                                    ? <div className="w-full text-center"><Loader /></div>
                                    : <Button className="w-full" type="submit" color="sky">Search</Button>
                                }
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </Card>
    
            {/* Dialog remains at the bottom */}
            <Dialog open={isOpen} onClose={setIsOpen}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogBody>
                    <video controls className="w-full">
                        <source src={mp4} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Divider />
                    <div>
                        <b className="text-white">Keywords Generated</b>
                        <p className="text-white">{keywordsGenerated}</p>
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Search;
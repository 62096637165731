import React, {useState} from 'react';
import Card from "../components/card";
import {FieldGroup, Fieldset, Field} from "../components/fieldset";
import {Button} from "../components/button";
import {Textarea} from "../components/textarea";
import client from "../lib/axios";
import {useNavigate} from "react-router-dom";
import {Loader} from "../components/loader";

const Assistant = () => {
    const defaultText = "How can I help you today?";
    const [currentValue, setCurrentValue] = useState(defaultText);
    const [loading, setLoading] = useState();
    const navigate = useNavigate()

    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        client.post('/assistant', {message}, {
            headers: {
                "Authorization": "SeedonkSession " + sessionStorage.getItem("sid")
            }
        })
            .then((response) => {
                setCurrentValue(response.data.message);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    sessionStorage.removeItem("sid")
                    navigate("/login")
                } else {
                    console.error(error)
                    setCurrentValue("Error occurred while processing your request.");
                }
            })
            .finally(() => {
                setMessage("")
                setLoading(false)
            })

    }

    return <div className="flex items-center md:items-start justify-center h-screen md:mt-[10vh] md:h-auto">
        <Card>
            <form onSubmit={handleSubmit}>
                <Fieldset>
                    <div className="flex justify-between">
                        <img className="h-12" src="/img/myq_logo.png" alt="myq logo"/>
                        <h1 className="mt-3 text-xl mb-4 text-zinc-500 font-sans">Smart Assistant</h1>
                    </div>
                    <FieldGroup>
                        <Field>
                            <Textarea rows="12" disabled="true" value={currentValue} onChange={() => {
                            }}></Textarea>
                        </Field>
                        <Field>
                            <Textarea rows="2" placeholder="Message smart assistant" value={message}
                                      onChange={(e) => setMessage(e.target.value)}></Textarea>
                        </Field>
                        <Field>
                            {loading
                                ? <div className="w-full text-center"><Loader/></div>
                                : <Button className="w-full" type="submit" color="sky">Submit</Button>
                            }
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </form>
        </Card>
    </div>
};

export default Assistant;
export default function Card({children}) {
    return (
        <div>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mx-auto min-w-[350px] sm:min-w-[400px]">
                    <div className="overflow-visible bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}